import * as Yup from 'yup';

import { NotificationsPayload } from 'state/account/account.services';

import { formatPhoneNumber, removeChars } from 'util/string';

export const initialValuesNotificationsSection = (allNotificationPreferences?: NotificationsPayload) => {
    return {
        NewScript: {
            Value: allNotificationPreferences?.NewScriptEmail
                ? 'Email'
                : allNotificationPreferences?.NewScriptPhone
                ? 'Phone'
                : allNotificationPreferences?.NewScriptText
                ? 'Text'
                : 'none',
            EmailAddress: allNotificationPreferences?.NewScriptEmailAddress || '',
            PhoneNumber: formatPhoneNumber(allNotificationPreferences?.NewScriptPhoneNumber, 'parentheses') || '',
            TextNumber: formatPhoneNumber(allNotificationPreferences?.NewScriptTextNumber, 'parentheses') || ''
        },
        RefillReminder: {
            Value: allNotificationPreferences?.RefillReminderEmail
                ? 'Email'
                : allNotificationPreferences?.RefillReminderPhone
                ? 'Phone'
                : allNotificationPreferences?.RefillReminderText
                ? 'Text'
                : 'none',
            EmailAddress: allNotificationPreferences?.RefillReminderEmailAddress || '',
            PhoneNumber: formatPhoneNumber(allNotificationPreferences?.RefillReminderPhoneNumber, 'parentheses') || '',
            TextNumber: formatPhoneNumber(allNotificationPreferences?.RefillReminderTextNumber, 'parentheses') || ''
        },
        OrderShipped: {
            Value: allNotificationPreferences?.OrderShippedEmail
                ? 'Email'
                : allNotificationPreferences?.OrderShippedPhone
                ? 'Phone'
                : allNotificationPreferences?.OrderShippedText
                ? 'Text'
                : 'none',
            EmailAddress: allNotificationPreferences?.OrderShippedEmailAddress || '',
            PhoneNumber: formatPhoneNumber(allNotificationPreferences?.OrderShippedPhoneNumber, 'parentheses') || '',
            TextNumber: formatPhoneNumber(allNotificationPreferences?.OrderShippedTextNumber, 'parentheses') || ''
        },
        Marketing: {
            Value: allNotificationPreferences?.MarketingEmail
                ? 'Email'
                : allNotificationPreferences?.MarketingPhone
                ? 'Phone'
                : allNotificationPreferences?.MarketingText
                ? 'Text'
                : 'none',
            EmailAddress: allNotificationPreferences?.MarketingEmailAddress || '',
            PhoneNumber: formatPhoneNumber(allNotificationPreferences?.MarketingPhoneNumber, 'parentheses') || '',
            TextNumber: formatPhoneNumber(allNotificationPreferences?.MarketingTextNumber, 'parentheses') || ''
        }
    };
};

export const NotificationSectionSchema = (validatePhoneNumber: Function, t: Function) => {
    const phoneValidation = Yup.string()
        .required(t('forms.errorMessages.invalidPhoneNumber'))
        .min(14, t('forms.errorMessages.invalidPhoneNumber'))
        .test('is-valid-phone', t('forms.errorMessages.invalidUSNumber'), async function (value) {
            if (!value || (value && removeChars(value).length < 10)) {
                return true;
            }
            const { path, createError } = this;
            const isValid = await validatePhoneNumber(value);
            if (!isValid) {
                return createError({ path, message: t('forms.errorMessages.invalidUSNumber') });
            }
            return true;
        });

    const emailValidation = Yup.string()
        .email(t('forms.errorMessages.invalidEmail'))
        .required(t('forms.errorMessages.invalidEmail'));

    const createNotificationSchema = (valueField: string) =>
        Yup.object().shape({
            EmailAddress: Yup.string().when(valueField, {
                is: 'Email',
                then: emailValidation
            }),
            PhoneNumber: Yup.string().when(valueField, {
                is: 'Phone',
                then: phoneValidation
            }),
            TextNumber: Yup.string().when(valueField, {
                is: 'Text',
                then: phoneValidation
            })
        });

    return Yup.object().shape({
        NewScript: createNotificationSchema('Value'),
        RefillReminder: createNotificationSchema('Value'),
        OrderShipped: createNotificationSchema('Value'),
        Marketing: createNotificationSchema('Value')
    });
};
