/**
 * Utility function that splits the text by commas and removes any leading or trailing spaces
 * @param text Plain text coming from Drupal
 * @returns An Array of strings
 */
export function transformExcludedPlans(text: string): string[] {
    if (text == null) return [];
    const strArray: string[] = text.split(',').map((str) => str.trim());
    return strArray;
}
