import { Field, Form, Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { LOCATION_EXCLUDED_PLANS } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

// Ui-kit
import Button from 'ui-kit/button/button';
import FormSelect from 'ui-kit/form-select/form-select';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import Text from 'ui-kit/text/text';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { VerifySmsModal } from 'components/verify-sms-modal';

import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent,
    VerifySmsRequestCanceled
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

// State
import {
    accountCancelSmsRequestRoutine,
    accountCheckPendingSmsRequestsRoutine,
    accountFetchNotificationsRoutine,
    accountFetchPlansRoutine,
    accountSendSmsConfirmationsRoutine,
    accountUpdateNotificationsRoutine
} from 'state/account/account.routines';
import {
    accountAllSmsNumbersSelector,
    accountNotificationsSelector,
    accountPlansSelector,
    accountProfileSelector,
    accountSmsCancelingRequestSelector
} from 'state/account/account.selectors';
import AccountService, { NotificationsPayload, ProfileObjectPayload } from 'state/account/account.services';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { familyProfileDependentsSelector } from 'state/family-profile/family-profile.selectors';

// Const
import { marketingNotificationOptions, notificationOptions } from 'const/options';

import { SmsTextNumbersToVerify, VerifyPhoneNumberData } from 'types/sms';

import { transformExcludedPlans } from 'util/contact-preferences';
import { mapCaregiverAndFamilyMembers } from 'util/depentent';
import { removeChars, replaceStringWith } from 'util/string';

import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

// Providers
import { NotificationSectionKeys } from './notification-settings.props';
import { initialValuesNotificationsSection, NotificationSectionSchema } from './notification-settings.validations';

const NotificationsSettings = ({ data }: { data: GatsbyTypes.ContactPreferencesPageDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const allNotificationPreferences = useSelector(accountNotificationsSelector);
    const smsCancelingReqeust = useSelector(accountSmsCancelingRequestSelector);
    const verifiedSmsNumbers = useSelector(accountAllSmsNumbersSelector);
    const profileObject = useSelector(accountProfileSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const familyMembersData = useSelector(familyProfileDependentsSelector);

    const [hideMobileNotification, setHideMobileNotifications] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const notificationSections: NotificationSectionKeys[] = useMemo(() => {
        return ['NewScript', 'RefillReminder', 'OrderShipped', 'Marketing'];
    }, []);

    const familyMembers = useMemo(() => {
        if (familyMembersData.length > 0) {
            const familyMembers = mapCaregiverAndFamilyMembers(
                profileObject as ProfileObjectPayload,
                familyMembersData,
                true
            );
            return familyMembers;
        } else {
            return null;
        }
    }, [familyMembersData, profileObject]);

    const initialValues = useMemo(
        () => initialValuesNotificationsSection(allNotificationPreferences),
        [allNotificationPreferences]
    );

    const resetContactPreferences = useCallback(() => {
        setIsLoading(true);
        dispatch(accountFetchNotificationsRoutine.trigger({ onSuccess: () => setIsLoading(false) }));
    }, [dispatch]);

    const handleCancelSmsVerification = useCallback(() => {
        if (!smsCancelingReqeust) {
            dispatch(
                accountCancelSmsRequestRoutine.trigger({
                    onSuccess: () => {
                        resetContactPreferences();
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: <VerifySmsRequestCanceled />,
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        }
    }, [dispatch, resetContactPreferences, smsCancelingReqeust, t]);

    const handleDisplayVerifySmsModal = useCallback(
        (submitValues: any) => {
            dispatch(
                accountCheckPendingSmsRequestsRoutine.trigger({
                    onSuccess: () => {
                        // dispatch the new verification Modal
                        dispatch(
                            openModal({
                                showClose: false,
                                bodyContent: (
                                    <VerifySmsModal
                                        t={t}
                                        dispatch={dispatch}
                                        valuesToSubmit={submitValues}
                                        resetContactPreferences={resetContactPreferences}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('button.cancel'),
                                        variant: 'text',
                                        onClick: () => {
                                            dispatch(setBusyModal(true));
                                            handleCancelSmsVerification();
                                        },
                                        async: true
                                    }
                                ],
                                backdrop: 'static'
                            })
                        );
                    }
                })
            );
        },
        [dispatch, handleCancelSmsVerification, resetContactPreferences, t]
    );

    const saveNotificationPreferences = useCallback(
        (values: Partial<NotificationsPayload>, submitProps: FormikHelpers<typeof initialValues>) => {
            const submitValues: Partial<NotificationsPayload> = { ...values };
            let hasPhoneNotification = false;
            const phoneNumbersToVerify: VerifyPhoneNumberData[] = [];

            notificationSections.forEach((sectionKey) => {
                const sectionSelectedValue = values[`${sectionKey}Value` as keyof Partial<NotificationsPayload>];

                if (sectionSelectedValue) {
                    notificationOptions.forEach((opt) => {
                        if (opt.value === sectionSelectedValue) {
                            submitValues[`${sectionKey}${opt.value}` as keyof NotificationsPayload] = true as any;
                        } else {
                            submitValues[`${sectionKey}${opt.value}` as keyof NotificationsPayload] = undefined;
                        }

                        if (sectionSelectedValue === 'Text') {
                            submitValues[`${sectionKey}TextNumber`] = values[`${sectionKey}TextNumber`];
                        } else if (sectionSelectedValue === 'Phone') {
                            submitValues[`${sectionKey}PhoneNumber`] = values[`${sectionKey}PhoneNumber`];
                        } else {
                            submitValues[`${sectionKey}PhoneNumber`] = '';
                            submitValues[`${sectionKey}TextNumber`] = '';
                        }
                    });
                }

                if (!values[`${sectionKey}Phone`]) {
                    submitValues[`${sectionKey}PhoneNumber`] = '';
                }

                if (!values[`${sectionKey}Text`]) {
                    submitValues[`${sectionKey}TextNumber`] = '';
                }

                if (values[`${sectionKey}Phone`] === true) {
                    hasPhoneNotification = true;
                }

                const unformattedPhoneNumber = replaceStringWith(
                    values[`${sectionKey}TextNumber`] || '',
                    new RegExp(/[^0-9]/g),
                    ''
                );

                if (
                    values[`${sectionKey}Text`] &&
                    values[`${sectionKey}TextNumber`] !== allNotificationPreferences?.[`${sectionKey}TextNumber`] &&
                    !verifiedSmsNumbers.includes(unformattedPhoneNumber)
                ) {
                    phoneNumbersToVerify.push({
                        phoneNumber: unformattedPhoneNumber,
                        smsType: `${sectionKey}Text`
                    });
                }
            });

            submitValues.ConsentAutoCalls = hasPhoneNotification;

            if (phoneNumbersToVerify.length > 0) {
                const phoneNumObj: SmsTextNumbersToVerify = {};
                phoneNumbersToVerify.filter((value) => {
                    if (phoneNumObj[value.phoneNumber]) {
                        phoneNumObj[value.phoneNumber].push(value.smsType);
                        return false;
                    }
                    phoneNumObj[value.phoneNumber] = [value.smsType];
                    return true;
                });

                dispatch(
                    accountSendSmsConfirmationsRoutine.trigger({
                        phoneNumbers: phoneNumObj,
                        onSuccess: () => {
                            handleDisplayVerifySmsModal(submitValues);
                        }
                    })
                );
            } else {
                dispatch(
                    accountUpdateNotificationsRoutine.trigger({
                        ...submitValues,
                        onSuccess: () => {
                            submitProps.resetForm({ values: initialValues });
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <UpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.notifications')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'ContactPreferenceUpdateProfile'
                                        }
                                    ]
                                })
                            );
                            resetContactPreferences();
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            icon="alert"
                                            headerText={t('modals.updateProfile.error')}
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.notifications')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'ContactPreferenceUpdateProfileError'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            }
        },
        [
            allNotificationPreferences,
            dispatch,
            handleDisplayVerifySmsModal,
            notificationSections,
            resetContactPreferences,
            t,
            verifiedSmsNumbers
        ]
    );

    const handleFormSubmission = async (formik: FormikProps<any>) => {
        const errors = await formik.validateForm();

        const hasErrors = Object.keys(errors).length > 0;

        if (!hasErrors) {
            formik.handleSubmit();
        } else {
            scrollToError(errors);
        }
    };

    function scrollToError(errors: FormikErrors<any>) {
        const findFirstError = (errors: any, parentKey = ''): string | null => {
            for (const key in errors) {
                if (typeof errors[key] === 'string' && errors[key]) {
                    return parentKey ? `${parentKey}.${key}` : key;
                } else if (typeof errors[key] === 'object' && errors[key] !== null) {
                    const nestedKey = findFirstError(errors[key], parentKey ? `${parentKey}.${key}` : key);
                    if (nestedKey) {
                        return nestedKey;
                    }
                }
            }
            return null;
        };

        const firstErrorField = findFirstError(errors);

        if (firstErrorField) {
            const errorElement = document.getElementsByName(`${firstErrorField}`)[0];

            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                errorElement.focus();
                errorElement.blur();
            }
        }
    }

    const handleViewTerms = () => {
        const bodyContent = data?.termsAndConditions?.nodes?.[0]?.relationships?.field_landing_page_content?.map(
            (paragraph: any, index: number) => (
                <React.Fragment key={`subhead_${index}`}>
                    {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                </React.Fragment>
            )
        );

        dispatch(
            openModal({
                showClose: true,
                className: 'text-left',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        eyebrowText={t(`pages.profile.notifications.heading`)}
                        title={t(`pages.profile.notifications.termsAndConditionsTitle`)}
                        body={
                            bodyContent && bodyContent.length > 0 ? <React.Fragment>{bodyContent}</React.Fragment> : ''
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const showConfirmationModal = (
        values: Partial<NotificationsPayload>,
        submitProps: FormikHelpers<typeof initialValues>
    ) => {
        const bodyContent = data?.confirmation?.nodes?.[0]?.relationships?.field_landing_page_content?.map(
            (paragraph: any, index: number) => (
                <React.Fragment key={`subhead_${index}`}>
                    {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                </React.Fragment>
            )
        );

        dispatch(
            openModal({
                showClose: true,
                className: 'text-left',
                onClose: () => {
                    resetContactPreferences();
                },
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t(`pages.profile.notifications.confirmPreferencesTitle`)}
                        body={
                            bodyContent && bodyContent.length > 0 ? <React.Fragment>{bodyContent}</React.Fragment> : ''
                        }
                    />
                ),
                ctas: [
                    {
                        label: t('button.confirm'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(setBusyModal(true));
                            saveNotificationPreferences(values, submitProps);
                        },
                        async: true
                    },
                    {
                        label: t('button.cancel'),
                        variant: 'text',
                        onClick: () => {
                            resetContactPreferences();
                            dispatch(closeModal({}));
                        },
                        className: 'p-4'
                    }
                ]
            })
        );
    };

    const validatePhoneNumber = async (phoneNumber: string): Promise<boolean> => {
        if (!phoneNumber) {
            return false;
        }
        try {
            const response = await AccountService.validatePhoneNumber().post([removeChars(phoneNumber)]);
            return response.phoneNumberVerifications[0].location?.toLowerCase() === 'united states';
        } catch {
            return false;
        }
    };

    const mapFormikValuesToApiFormat = (formikValues: any) => {
        const mapSection = (sectionKey: string, sectionValue: any) => {
            const formattedPhoneNumber = (phoneNumber?: string) => phoneNumber?.replace(/\D/g, '');

            return {
                [`${sectionKey}Email`]: sectionValue.Value === 'Email',
                [`${sectionKey}EmailAddress`]: sectionValue.EmailAddress,
                [`${sectionKey}Phone`]: sectionValue.Value === 'Phone',
                [`${sectionKey}PhoneNumber`]: formattedPhoneNumber(sectionValue.PhoneNumber || ''),
                [`${sectionKey}Text`]: sectionValue.Value === 'Text',
                [`${sectionKey}TextNumber`]: formattedPhoneNumber(sectionValue.TextNumber || '')
            };
        };

        return {
            ...mapSection('NewScript', formikValues.NewScript),
            ...mapSection('RefillReminder', formikValues.RefillReminder),
            ...mapSection('OrderShipped', formikValues.OrderShipped),
            ...mapSection('Marketing', formikValues.Marketing)
        };
    };

    const handleFormSubmit = async (values: typeof initialValues, submitProps: FormikHelpers<typeof initialValues>) => {
        const payload: NotificationsPayload = {
            ...allNotificationPreferences!,
            ...mapFormikValuesToApiFormat(values)
        };

        if (data.confirmation?.nodes[0]) {
            showConfirmationModal(payload, submitProps);
        } else {
            saveNotificationPreferences(payload, submitProps);
        }
    };

    const renderField = (sectionKey: NotificationSectionKeys, formik: FormikProps<typeof initialValues>) => {
        const selectedValue = formik.values[sectionKey]?.Value;

        return (
            <>
                {/* Email Field */}
                <div
                    style={{
                        display: selectedValue === 'Email' ? 'block' : 'none'
                    }}
                >
                    <Text
                        key={`${sectionKey}.EmailAddress`}
                        name={`${sectionKey}.EmailAddress`}
                        label={t('pages.profile.notifications.labels.email')}
                        onChange={(event) => {
                            formik.handleChange(event);
                            formik.validateField(`${sectionKey}.EmailAddress`);
                        }}
                        onBlur={formik.handleBlur}
                        touched={formik.touched[sectionKey]?.EmailAddress}
                        errors={formik.errors[sectionKey]?.EmailAddress as string}
                        value={formik.values[sectionKey].EmailAddress || ''}
                    />
                </div>

                {/* Phone Field */}
                <div
                    style={{
                        display: selectedValue === 'Phone' ? 'block' : 'none'
                    }}
                >
                    <PhoneNumberText
                        key={`${sectionKey}.PhoneNumber`}
                        name={`${sectionKey}.PhoneNumber`}
                        label={t('pages.profile.notifications.labels.phoneNumber')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        touched={formik.touched[sectionKey]?.PhoneNumber}
                        errors={formik.errors[sectionKey]?.PhoneNumber as string}
                        value={formik.values[sectionKey].PhoneNumber || ''}
                        countryCode={t(`countryCode`)}
                    />
                </div>

                {/* Text Field */}
                <div
                    style={{
                        display: selectedValue === 'Text' ? 'block' : 'none'
                    }}
                >
                    <PhoneNumberText
                        key={`${sectionKey}.TextNumber`}
                        name={`${sectionKey}.TextNumber`}
                        label={t('pages.profile.notifications.labels.phoneNumber')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors={formik.errors[sectionKey]?.TextNumber}
                        touched={formik.touched[sectionKey]?.TextNumber}
                        value={formik.values[sectionKey].TextNumber || ''}
                        countryCode={t(`countryCode`)}
                    />
                </div>
            </>
        );
    };

    useEffect(() => {
        if (profileObject !== undefined && (!accountPlans || accountPlans.length === 0)) {
            if (profileObject.isCaregiver && familyMembers && familyMembersData) {
                dispatch(accountFetchPlansRoutine({ familyMembers }));
            } else if (profileObject.epostPatientNum && !profileObject.isCaregiver) {
                dispatch(accountFetchPlansRoutine({ familyMembers: [profileObject.epostPatientNum] }));
            }
        }
    }, [profileObject, accountPlans, familyMembers, familyMembersData, dispatch]);

    useEffect(() => {
        const hasExcludedPlan = accountPlans
            ?.filter((plan) => plan.epostPatientNum === profileObject?.epostPatientNum)
            .some((plan) => transformExcludedPlans(LOCATION_EXCLUDED_PLANS).includes(plan.planName));
        if (hasExcludedPlan) {
            setHideMobileNotifications(hasExcludedPlan);
        }
    }, [accountPlans]);

    useEffect(() => {
        resetContactPreferences();
    }, [resetContactPreferences]);

    return (
        <div>
            <LoadingMessage isVisible={isLoading} text={t(`pages.profile.notifications.loading`)} />
            {!isLoading && (
                <Container fluid>
                    <Row>
                        <Col className="d-flex flex-column">
                            <div className="ml-3">
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={handleFormSubmit}
                                    enableReinitialize={true}
                                    validateOnChange={false}
                                    validateOnBlur={true}
                                    validate={async (values) => {
                                        try {
                                            await NotificationSectionSchema(validatePhoneNumber, t).validate(values, {
                                                abortEarly: false
                                            });
                                            return {};
                                        } catch (error) {
                                            if (error instanceof Yup.ValidationError) {
                                                const formattedErrors = error.inner.reduce(
                                                    (acc: any, currError: any) => {
                                                        const pathParts = currError.path.split('.');
                                                        const sectionKey = pathParts[0];
                                                        const fieldName = pathParts[1];

                                                        if (!acc[sectionKey]) {
                                                            acc[sectionKey] = {};
                                                        }

                                                        acc[sectionKey][fieldName] = currError.message;

                                                        return acc;
                                                    },
                                                    {}
                                                );
                                                return formattedErrors;
                                            }
                                            return {};
                                        }
                                    }}
                                >
                                    {(formik) => (
                                        <Form id="notification-form" autoComplete="off">
                                            {notificationSections.map((sectionKey) => (
                                                <div className="notification-form--section mb-4" key={sectionKey}>
                                                    <p className="profile-form-instructions">
                                                        {t(`pages.profile.notifications.sections.${sectionKey}.title`)}
                                                    </p>
                                                    <p className="ml-3">
                                                        {t(
                                                            `pages.profile.notifications.sections.${sectionKey}.heading`
                                                        )}
                                                    </p>
                                                    <Row className="d-sm-flex d-block">
                                                        <Col>
                                                            <Field
                                                                name={`${sectionKey}.Value`}
                                                                component={FormSelect}
                                                                options={
                                                                    sectionKey === 'Marketing'
                                                                        ? marketingNotificationOptions
                                                                        : hideMobileNotification
                                                                        ? marketingNotificationOptions
                                                                        : notificationOptions
                                                                }
                                                                value={formik.values[sectionKey].Value}
                                                                placeholder={t(
                                                                    'pages.profile.notifications.labels.preference'
                                                                )}
                                                                touched={formik.touched[sectionKey]?.Value}
                                                            />
                                                        </Col>
                                                        <Col>{renderField(sectionKey, formik)}</Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            <Row className="d-flex align-items-center">
                                                <Col lg={5} className="text-left order-2 order-lg-1">
                                                    <Button
                                                        className="sm-full md-full"
                                                        disabled={!formik.dirty || formik.isSubmitting}
                                                        label={t('pages.profile.notifications.labels.submit')}
                                                        variant="primary"
                                                        type="button"
                                                        onClick={() => handleFormSubmission(formik)}
                                                    />
                                                </Col>
                                                <Col className="text-right order-1 order-lg-1">
                                                    {data.termsAndConditions?.nodes[0] && (
                                                        <Button
                                                            className="sm-full md-full"
                                                            label={t(
                                                                'pages.profile.notifications.labels.termsAndConditions'
                                                            )}
                                                            variant="text"
                                                            type="button"
                                                            onClick={handleViewTerms}
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    );
};

export default NotificationsSettings;
