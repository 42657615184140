import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import NotificationsSettings from 'components/notification-settings/notification-settings';

import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

import './contact-preferences.style.scss';

const ContactPreferencesPage = ({ data }: { data: GatsbyTypes.ContactPreferencesPageDataQuery }) => {
    const { t } = useTranslation();

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.notifications.eyebrowText`)}
            title={t(`pages.profile.notifications.title`)}
            heading={t(`pages.profile.notifications.heading`)}
            subhead={data.header?.nodes[0]?.relationships?.field_landing_page_content?.map(
                (paragraph: any, index: number) => (
                    <React.Fragment key={`subhead_${index}`}>
                        {paragraph?.internal?.type &&
                            paragraphToComponent(paragraph?.internal?.type, paragraph, index + 10)}
                    </React.Fragment>
                )
            )}
        >
            <NotificationsSettings data={data} />
        </ProfileLayout>
    );
};

export default ContactPreferencesPage;

export const query = graphql`
    query ContactPreferencesPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        header: allNodeLandingPage(
            filter: { path: { alias: { eq: "/embedded/profile/contact-preferences-header" } } }
        ) {
            nodes {
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
        termsAndConditions: allNodeLandingPage(
            filter: { path: { alias: { eq: "/embedded/profile/contact-preferences-terms-and-conditions" } } }
        ) {
            nodes {
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
        confirmation: allNodeLandingPage(
            filter: { path: { alias: { eq: "/embedded/profile/contact-preferences-confirmation" } } }
        ) {
            nodes {
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
    }
`;
